import styled from "styled-components";
import { ReactComponent as QrCode } from "./qr-code.svg";

interface QRProps {
  text: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Header = styled.h1`
  font-size: 2rem;
  text-align: center;
`;

const QRCode = (props: QRProps) => {
  return (
    <Container>
      <Header>{props.text}</Header>
      <QrCode style={{ height: "40vmin" }} />
    </Container>
  );
};

export default QRCode;
