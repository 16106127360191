import styled from "styled-components";

interface SimpleTextProps {
  text: string;
}

const Header = styled.h1`
  font-size: 2rem;
  text-align: center;
`;

const SimpleText = (props: SimpleTextProps) => {
  return <Header>{props.text}</Header>;
};

export default SimpleText;
