import { isAndroid, isIphone } from "./utils";
import SimpleText from "./SimpleText";
import QRCode from "./Qr-Code";

const App = () => {
  if (isAndroid()) {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.TheSmartphoneOrchestra.TheGame"
    );
    
    setTimeout(function() {
      window.location.replace("https://smartphoneorchestra.com/")
    }, 15000);

    return <SimpleText text="Android!"></SimpleText>;
  }

  if (isIphone()) {
    window.location.replace(
      "https://apps.apple.com/app/spo-the-score/id1590147074"
    );

    setTimeout(function() {
      window.location.replace("https://smartphoneorchestra.com/")
    }, 15000);
      
    return <SimpleText text="iPhone!"></SimpleText>;
  }

  return <QRCode text="Please scan the QR-code with your smartphone"></QRCode>;
};

export default App;
